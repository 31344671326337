import React from 'react'
import { cn } from '@/lib/utils'
import { HeadingProps, ParagraphProps } from './types'

/**
 * H1 Component
 *
 * A component for rendering an <h1> element.
 *
 * @param {string} props.className - default: banner-title text-blue-prussian
 * @param {string} props.children - The content to display within the <h1> element.
 */
export function H1({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h1 className={cn('text-blue-prussian banner-title', className)} {...props}>
        {children}
      </h1>
    </>
  )
}

/**
 * H2 Component
 *
 * A component for rendering an <h2> element.
 *
 * @param {string} props.className - default: section-title text-black-pearl
 * @param {string} props.children - The content to display within the <h2> element.
 */
export function H2({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h2 className={cn('text-black-pearl section-title', className)} {...props}>
        {children}
      </h2>
    </>
  )
}

/**
 * H3 Component
 *
 * A component for rendering an <h3> element.
 *
 * @param {string} props.className - default: text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold
 * @param {string} props.children - The content to display within the <h3> element.
 */
export function H3({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h3 className={cn('text-lg font-bold text-blue-cyan xl:text-[32px] xl:leading-[normal]', className)} {...props}>
        {children}
      </h3>
    </>
  )
}

/**
 * H4 Component
 *
 * A component for rendering an <h4> element.
 *
 * @param {string} props.className - default: section-subtitle text-blue-cyan
 * @param {string} props.children - The content to display within the <h4> element.
 */
export function H4({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h4 className={cn('text-blue-cyan section-subtitle', className)} {...props}>
        {children}
      </h4>
    </>
  )
}

/**
 * H5 Component
 *
 * A component for rendering an <h5> element.
 *
 * @param {string} props.className - default: section-body-title text-blue-cyan
 * @param {string} props.children - The content to display within the <h5> element.
 */
export function H5({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h5 className={cn('text-blue-cyan section-body-title', className)} {...props}>
        {children}
      </h5>
    </>
  )
}

/**
 * H6 Component
 *
 * A component for rendering an <h6> element.
 *
 * @param {string} props.className - default: text-blue-cyan text-[14px] lg:text-base leading-[21px] lg:leading-6
 * @param {string} props.children - The content to display within the <h6> element.
 */
export function H6({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h6 className={cn('text-sm text-blue-cyan lg:text-base', className)} {...props}>
        {children}
      </h6>
    </>
  )
}

/**
 * P Component
 *
 * A component for rendering an <p> element.
 *
 * @param {string} props.className - default: text-twilight text-base lg:text-xl leading-6 lg:leading-[28px]
 * @param {string} props.children - The content to display within the <p> element.
 */
export function P({ className, children, ...props }: ParagraphProps) {
  return (
    <p className={cn('text-twilight body-content', className)} {...props}>
      {children}
    </p>
  )
}

/**
 * SectionParagraph Component
 *
 * A component for rendering an <p> element.
 *
 * @param {string} props.className - default: blue-dark-midnight body-content.
 * @param {string} props.children - The content to display within the <p> element.
 */
export function SectionParagraph({ className, children, ...props }: ParagraphProps) {
  return (
    <p className={cn('text-blue-cyan body-content', className)} {...props}>
      {children}
    </p>
  )
}
