import React from 'react'
import useResponsive from '@/hooks/responsive'
import { SectionHeader } from '@/v1/@core/section-header'
import { parsedContent } from '@/lib/utils'
import HomeTabBoxGenerate from '@/custom-components/tab-section/HomeTabBoxGenerate'

const HomePayment = () => {
  const text62 = 'lg:text-[62px] text-[28px]'

  return (
    <>
      <div className='bg-white-50'>
        <div className='container py-[32px] lg:py-[68px]'>
          <SectionHeader
            title={parsedContent('PayToMe -', text62)}
            titlePart={parsedContent(`Your Complete Payment Solution`, text62)}
            isInlineTitle
          />

          <HomeTabBoxGenerate
            pageKey={'homepage_new'}
            className='bg-white-50'
            tabClassNames={{
              tab_wrapper: 'max-w-[1512px] mx-auto py-[13px] border-none',
              body_wrapper: 'max-w-[1512px] mx-auto',
              parent_content_wrapper: 'mt-2',
              content_wrapper: 'items-center',
              content: 'lg:order-2 order-2',
              image_wrapper: 'lg:order-1 order-1',
              // image: 'sm:max-w-[606px] max-w-[100%]'
              image: 'xl:max-w-[606px] mx-auto md:max-w-[400px] max-w-[250px]'
            }}
          />
        </div>
      </div>
    </>
  )
}

export default HomePayment
