import Link from 'next/link'
import React, { HTMLAttributes } from 'react'
import { cn } from '@/lib/utils'
import IconLists from '@/helpers/IconLists'

export interface CallToActionButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  href?: string
  text: string
  icon?: React.ReactNode | boolean
  textColor?: string
  fontSize?: string
  mdFontSize?: string
  lgFontSize?: string
  xlFontSize?: string
}

function CallToActionButton({
  href = '#',
  text,
  icon,
  textColor = 'white',
  fontSize = 'base',
  lgFontSize = 'xl',
  mdFontSize = 'xl',
  xlFontSize = '2xl',
  className,
  ...rest
}: CallToActionButtonProps) {
  return (
    <Link
      href={href}
      className={cn(
        `max-h-[44px] rounded-full bg-blue-ultramarine px-[31px] py-[13px] shadow transition-all duration-150 hover:bg-[#242265] lg:max-h-[60px] text-${textColor} md:text-${mdFontSize} lg:text-${lgFontSize} xl:text-${xlFontSize} text-${fontSize} inline-flex items-center gap-3 font-inter font-bold`,
        className
      )}
      {...rest}
    >
      <span>{text}</span>
      {typeof icon === 'boolean' ? IconLists.bigRightArrowIcon() : typeof icon === 'object' && icon}
    </Link>
  )
}

export default CallToActionButton
