import React, { ReactNode } from 'react'
import { InterTypography } from '@/custom-styles'
import parse from 'html-react-parser'
import Box from '@mui/material/Box'
import GlobalSaveButton from '@/custom-components/buttons/GlobalSaveButton'
import { Icon } from '@iconify/react'
import { cn } from '@/lib/utils'
// import useTypeWriter from '@/hooks/tsx/use-typewriter'
import Image from 'next/image'

import Typewriter from 'typewriter-effect'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import IconLists from '@/helpers/IconLists'
import useResponsive from '@/hooks/responsive'

interface BannerWithTypeWriterProps {
  children?: ReactNode
  data: {
    willHaveGradientShade?: boolean
    slug: {
      html: string
      classes?: string
      sx?: Record<string, any>
    }
    title: {
      html: string
      sx: Record<string, any>
    }
    subtitle: {
      html: string
      sx: Record<string, any>
    }
    img: {
      alt: string
      src: string
      customStyles: Record<string, any>
      bodySx: Record<string, any>
    }
    content_col_size?: number
    button: {
      html: string
      url: string
      sx?: Record<string, any>
    }
    type_writer: {
      words: string[]
      delay?: number
      classes?: string
    }
  }
  classNames?: {
    section?: string
    img?: string
    imgShade?: string
    title?: string
    slug?: string
    subtitle?: string
  }
}

// const SCREENS = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

const BannerWithTypeWriter: React.FC<BannerWithTypeWriterProps> = ({ classNames, data, children }) => {
  const { mobileAll } = useResponsive()
  const { type_writer, willHaveGradientShade = false, slug, title, subtitle, img, button } = data

  const titleHtml = title?.html || ''
  const slugHtml = slug?.html || ''
  const titleSx = title?.sx || {}
  const subtitleHtml = subtitle?.html || ''
  const subtitleSx = subtitle?.sx || {}
  const { alt, src, customStyles, bodySx } = img

  // const { text, isBlinking } = useTypeWriter(type_writer.words, type_writer?.delay)

  // console.log({DD: type_writer?.words})

  return (
    <>
      <section className={cn(`bg-section-gradient py-[32px] md:py-[50px] xl:py-[68px]`, classNames?.section)}>
        <div className={'container flex flex-col items-center justify-between sm:flex-row'}>
          <div className={'w-full text-center sm:w-[initial] sm:text-start'}>
            <div
              data-aos='fade-up'
              data-aos-delay={300}
              data-aos-duration={1200}
              className={cn(
                'font-inter text-[28px] font-bold capitalize text-red-600 lg:text-[#0600F9] xl:text-[28px] 2xl:text-[40px]',
                slug?.classes
              )}
            >
              {slugHtml}
            </div>
            <div
              data-aos='fade-up'
              data-aos-delay={600}
              data-aos-duration={1200}
              className={'font-inter text-[28px] text-[#022A4E] lg:text-[28px] xl:text-[64px]'}
              style={{ ...titleSx }}
            >
              {parse(titleHtml)}
              {!!type_writer?.words?.length && (
                <span
                  className={cn(
                    `text-8 block font-inter text-[28px] font-semibold text-amber-400 md:inline-block lg:text-[28px] xl:text-[46px] 2xl:text-[64px]`,
                    type_writer?.classes
                  )}
                >
                  <Typewriter
                    options={{
                      strings: type_writer.words,
                      autoStart: true,
                      loop: true
                      // delay: 200,
                      // skipAddStyles:true
                    }}
                  />

                  {/*{text}*/}
                  {/*<span className={'font-thin'}>{isBlinking ? '|' : ''}</span>*/}
                </span>
              )}
            </div>

            <Box
              data-aos='fade-up'
              data-aos-delay={900}
              data-aos-duration={1200}
              sx={{
                ...InterTypography,
                ['*']: { ...InterTypography },
                mt: { xs: '6px', md: '3px', lg: '10px', xl: '0px' },
                color: '#FFF',
                fontStyle: 'normal',
                fontWeight: 500,
                wordBreak: 'break-word',
                display: { sm: 'block', xs: 'none' },
                fontSize: { xs: '20px', md: '24px' },
                textAlign: { xs: 'left', md: 'left' },
                lineHeight: { xs: '30px', md: '32px' },
                ['p']: {
                  fontSize: { xs: '16px', md: '20px' },
                  textAlign: { xs: 'center', md: 'left' },
                  fontWeight: 400,
                  lineHeight: { xs: '22px', md: '28px' }
                },
                ['ul']: { ml: { xs: 0, md: 8 } },
                maxWidth: { xs: '375px', md: '760px' },
                mb: { xs: '6px', lg: '20px', sm: '20px', xl: '0px' },
                ...subtitleSx
              }}
            >
              {parse(subtitleHtml)}
            </Box>

            <>
              {button?.html && !mobileAll && (
                <div
                  className='mt-6 items-center justify-center sm:mt-8 sm:justify-start sm:px-2'
                  data-aos='fade-up'
                  data-aos-delay={1200}
                  data-aos-duration={1200}
                >
                  <CallToActionButton
                    className={
                      'bg-white font-medium text-twilight transition-all duration-150 hover:bg-[#656FFB] hover:text-[#fff]'
                    }
                    href={'/pricing'}
                    text={'Get Started With Us'}
                  />
                </div>
              )}
            </>
          </div>

          <div className={'h-auto max-h-[562px] w-full sm:max-w-[300px] lg:max-w-[630px]'}>
            <Image
              data-aos='zoom-in-up'
              data-aos-delay={300}
              data-aos-duration={1500}
              data-aos-anchor-placement='center-bottom'
              src={src}
              alt={alt}
              priority
              className={'h-auto w-full'}
            />

            <div className={'flex justify-center md:hidden'}>
              {button?.html && (
                <div className='mt-6 flex items-center justify-center sm:mt-8 sm:justify-start sm:px-2'>
                  <CallToActionButton
                    className={
                      'bg-white font-medium text-twilight transition-all duration-150 hover:bg-[#656FFB] hover:text-[#fff]'
                    }
                    href={'/pricing'}
                    text={'Get Started With Us'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {children && <>{children}</>}
      </section>
    </>
  )
}

export default BannerWithTypeWriter
